var className = "inverted";
var scrollTrigger = 60;

window.onscroll = function () {
  // We add pageYOffset for compatibility with IE.
  if (window.scrollY >= scrollTrigger || window.pageYOffset >= scrollTrigger) {
    document.getElementsByTagName("header")[0].classList.add(className);
  } else {
    document.getElementsByTagName("header")[0].classList.remove(className);
  }
};

// for adding link tag
function loadLink(relData, hrefData) {
  const isLinkAvailable = document.getElementById("blog_id");
  if (relData && hrefData) {
    if (isLinkAvailable) {
      isLinkAvailable.href = hrefData;
      isLinkAvailable.rel = relData;
    } else {
      let link = document.createElement("link");
      link.rel = relData;
      link.href = hrefData;
      link.id = "blog_id";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
  } else {
    isLinkAvailable?.remove();
    console.log("----removed");
  }
}

// for adding meta title, metaTitle, metaDescription tag
function loadMeta(titData, metaTitData, metaDesData) {
  const istitDataAvailable = document.getElementById("title_id");
  const ismetaTitDataAvailable = document.getElementById("metaTitle_id");
  const ismetaDesDataAvailable = document.getElementById("metaDis_id");
  const ismetaDescriptionAvailable = document.getElementById("metaDiscription_id");
  
  if (titData && istitDataAvailable) {
    istitDataAvailable.text = titData;
  } else if (!titData && istitDataAvailable) {
    istitDataAvailable.text = "Brooklyn Data Co.";
  } else {
    let title = document.createElement("title");
    title.text = titData ?? "Brooklyn Data Co.";
    title.id = "title_id";
    document.getElementsByTagName("head")[0].appendChild(title);
  }

  if (metaTitData && ismetaTitDataAvailable) {
    //ismetaTitDataAvailable.name = 'title'
    ismetaTitDataAvailable.content = metaTitData;
  } else if (!metaTitData && ismetaTitDataAvailable) {
    //ismetaTitDataAvailable.name = 'title'
    ismetaTitDataAvailable.content = "Brooklyn Data Co.";
  } else {
    let metaTitle = document.createElement("meta");
    metaTitle.content = metaTitData ?? "Brooklyn Data Co.";
    metaTitle.setAttribute("property", "og:title");
    metaTitle.id = "metaTitle_id";
    document.getElementsByTagName("head")[0].appendChild(metaTitle);
  }

  if (metaDesData && ismetaDesDataAvailable) {
    ismetaDesDataAvailable.content = metaDesData;
  } else if (!metaDesData && ismetaDesDataAvailable) {
    ismetaDesDataAvailable.content = "Brooklyn Data Co.";
  } else {
    ismetaDesDataAvailable?.remove();
    let metaDes = document.createElement("meta");
    metaDes.content = metaDesData;
    metaDes.setAttribute("property", "og:description");
    metaDes.id = "metaDis_id";
    document.getElementsByTagName("head")[0].appendChild(metaDes);
  }

  if (metaDesData && ismetaDescriptionAvailable) {
    ismetaDescriptionAvailable.name = "description";
    ismetaDescriptionAvailable.content = metaDesData;
  } else if (!metaDesData && ismetaDescriptionAvailable) {
    ismetaDescriptionAvailable.name = "description";
    ismetaDescriptionAvailable.content = "Brooklyn Data Co.";
  } else {
    ismetaDescriptionAvailable?.remove();
    let metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.id = "metaDiscription_id";
    metaDescription.content = metaDesData ?? "Brooklyn Data Co.";
    document.getElementsByTagName("head")[0].appendChild(metaDescription);
  }
}

// for adding meta image tag
function loadMetaImage(imgLink) {
  const isMetaImageAvailable = document.getElementById("img_id");
  const islogoMetaImageAvailable = document.getElementById("logo");
  if (imgLink && isMetaImageAvailable) {
    islogoMetaImageAvailable?.remove();
    isMetaImageAvailable.content = imgLink;
  } else if (!imgLink && isMetaImageAvailable) {
    islogoMetaImageAvailable?.remove();
    //isMetaImageAvailable.content = "/src/assets/img/bdcBlackLogo.svg";
    
  } else {
    islogoMetaImageAvailable?.remove();
    let metaImg = document.createElement("meta");
    metaImg.content = imgLink;
    metaImg.setAttribute("property", "og:image");
    metaImg.id = "img_id";
    document.getElementsByTagName("head")[0].appendChild(metaImg);
  }
}

function pageMetaImage(imgLink) {
  const isMetaImageAvailable = document.getElementById("img_id");
  isMetaImageAvailable?.remove();
  const islogoMetaImageAvailable = document.getElementById("logo");
  islogoMetaImageAvailable?.remove();
  let metaImg = document.createElement("meta");
  metaImg.content = imgLink;
  metaImg.setAttribute("property", "og:image");
  metaImg.id = "logo";
  document.getElementsByTagName("head")[0].appendChild(metaImg);
}

function pageMetaTypeURL(url, type) {
  const isMetaUrlAvailable = document.getElementById("metaURL");
  const isMetaTypeAvailable = document.getElementById("metaTYPE");
  if (url && isMetaUrlAvailable) {
    isMetaUrlAvailable.content = url;
  } else if (!url && isMetaUrlAvailable) {
    isMetaUrlAvailable.content='';
  } else {
    let newUrl = document.createElement("meta");
    newUrl.setAttribute("property", "og:url");
    newUrl.content = url;
    newUrl.id = "metaURL";
    document.getElementsByTagName("head")[0].appendChild(newUrl);
  }

  if (type && isMetaTypeAvailable) {
    isMetaTypeAvailable.content = type;
  } else if (!type && isMetaTypeAvailable) {
    isMetaTypeAvailable.content ='';
  } else {
    let newType = document.createElement("meta");
    newType.setAttribute("property", "og:type");
    newType.content = type??" ";
    newType.id = "metaTYPE";
    document.getElementsByTagName("head")[0].appendChild(newType);
  }
}